<template>
<div class="mainform">
    <div class="form">
        <el-form ref="form" class="content" :model="form" label-width="170px" :disabled="isReadonly">
            <el-form-item label="物料编号"><span class="onlyText">{{form.materialCode}}</span>
            </el-form-item>
            <el-form-item label="物料描述"><span class="onlyText">{{form.materialDesc}}</span>
            </el-form-item>
            <el-form-item label="供应商名称"><span class="onlyText">{{form.supplierName}}</span>
            </el-form-item>
            <el-form-item label="物料类型"><span class="onlyText">{{form.materialType}}</span>
            </el-form-item>
            <el-form-item label="单位"><span class="onlyText">{{form.unit}}</span>
            </el-form-item>
            <el-form-item label="供应商物料编号">
                <el-input v-model="form.supplierMaterialCode"></el-input>
            </el-form-item>
            <el-form-item label="供应商物料描述">
                <el-input v-model="form.supplierMaterialDesc"></el-input>
            </el-form-item>
            <el-form-item label="LT交期">
                <el-input v-model="form.deliveryTime"></el-input>
            </el-form-item>
            <el-form-item label="是否贴条码">
                <el-radio v-model="form.isHasBarcode" label="是">是</el-radio>
                <el-radio v-model="form.isHasBarcode" label="否">否</el-radio>
            </el-form-item>
            <el-form-item label="最小批量">
                <el-input v-model="form.minBatch"></el-input>
            </el-form-item>
            <el-form-item label="ECN类别">
                <el-input v-model="form.ecnType"></el-input>
            </el-form-item>
            <el-form-item label="ECN变更描述">
                <el-input v-model="form.ecnUpdateDesc"></el-input>
            </el-form-item>
        </el-form>
    </div>
</div>
</template>

<script>
export default {
  name: 'MaterialBody',
  props: ['showData', 'isReadonly'],
  data: function () {
    return {
      form: this.showData
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .mainform{
    background: none;
    padding: 0;
    .form{
      background: none;
      .content{
        padding: 0px 0;
      }
    }
  }
.mainform .form .el-form .el-form-item:last-child {
    margin-bottom: 0px;
}
</style>
